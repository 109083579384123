import React, { useState, useEffect, useRef, useContext, memo, Suspense } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
// import MiniCart from '../../DynamicComponents/MiniCart'
// import MobileMenu from '../../CategoryMobileSidebar/MobileMenu'
// import MegaMenu from './HeaderMegaMenu/MegaMenu'
import { connect, useSelector, useDispatch } from 'react-redux'
import { GetSearchList } from '../../../../helpers/apiHandler'
import { FaBars, FaAngleDown, FaSearch } from 'react-icons/fa'
import { MdKeyboardArrowDown } from 'react-icons/md'
import PropTypes from 'prop-types'
import CustomButton from '../../DynamicComponents/CustomButton'
import './Header.scss'
import { FiUser } from 'react-icons/fi'
import { IoLocationOutline } from 'react-icons/io5'
import { AiOutlineSearch } from 'react-icons/ai'
import { SiteDataContext, ScrollContext, FooterContext } from '../../../../Routes/Routes'
import { selectLocation, removeLocations } from '../../../../redux/actions/loactionActions'
import store from '../../../../redux/store'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const MobileMenu = React.lazy(() => import('../../CategoryMobileSidebar/MobileMenu'))
const MiniCart = React.lazy(() => import('../../DynamicComponents/MiniCart'))
const MegaMenu = React.lazy(() => import('./HeaderMegaMenu/MegaMenu'))

let searchTimer
let cursorPointer = 0
const NewHeader = ({ isOpenAccount }) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const state = store.getState()
  const searchPlaceholder =
    'Enter a keyword, Ex: Year, Make, Model, Engine, Part Number....'
  const allowDropship = JSON.parse(localStorage?.getItem('profile'))?.allow_dropship
  const [toggleSidebar, setToggleSidebar] = useState(false)
  const [mobileMenuOne, setMobileMenuOne] = useState(false)
  const [toggleMegaMenu, setToggleMegaMenu] = useState(false)
  const [toggleSearchList, setToggleSearchList] = useState(false)
  const [searchData, setSearchData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [typedSearch, setTypedSearch] = useState('')
  const [megaOpen, setMegaOpen] = useState(false)
  const [siteData, setSiteData] = useState(null)
  const wrapperNav = useRef(null)
  const productRef = useRef(null)
  const cartRef = useRef(null)
  const wrapperCart = useRef(null)
  const { data } = useSelector(state => state.cart)
  const userData = useSelector(state => state.auth)
  const locations = useSelector(state => state.locations)
  const { siteDetails, setApplicationSearchCount } = useContext(SiteDataContext)
  const { scrollState, setScrollState } = useContext(ScrollContext)
  const { footerData } = useContext(FooterContext)
  const queryParams = new URLSearchParams(window.location.search)
  const location = useLocation()
  const [locationData, setLocationData] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState({})
  const [cursor, setCursor] = useState(0)
  const [allowedModules, setAllowedModules] = useState([])
  const totalQuantity = data.reduce((acc, item) => {
    acc += parseInt(item.quantity)
    return acc
  }, 0)
  
  useEffect(() => {
    if (!queryParams.get('search')) {
      setSearchValue('')
    }
  }, [location])

  useEffect(() => {
    if (queryParams.get('search')) {
      getSearchData({ target: { value: queryParams.get('search') } }, true)
    }
  }, [])

  useEffect(() => {
    if (state.auth) {
      setAllowedModules(state?.auth?.user?.allowedModules)
    } else {
      setAllowedModules([])
    }
  }, [state.auth])

  // const getLocations = async () => {
  //   GetData('customer/getLocations').then((response) => {
  //     if (response.status === true) {
  //       setLocationData(response?.result)
  //       if (JSON.parse(localStorage.getItem('selectedLocation')) === undefined || JSON.parse(localStorage.getItem('selectedLocation')) === null) {
  //         localStorage.setItem('selectedLocation', JSON.stringify(response?.result[0]))
  //       }
  //       if (response?.result?.length > 0) {
  //         setSelectedLocation(response?.result[0])
  //         dispatch(selectLocation(response?.result[0]))
  //       }
  //     } else {
  //       toastr.error(response.message, 'Error')
  //     }
  //   })
  // }

  useEffect(() => {
    if (locationData) {
      if (localStorage.getItem('selectedLocation')) {
        const location = JSON.parse(localStorage.getItem('selectedLocation'))
        const loc = locationData.filter((loc) => {
          return loc.id === location.id
        })
        if (loc.length > 0) {
          setSelectedLocation(loc[0])
          dispatch(selectLocation(loc[0]))
        } else {
          setSelectedLocation(locationData[0])
          dispatch(selectLocation(locationData[0]))
          localStorage.setItem('selectedLocation', JSON.stringify(locationData[0]))
        }
      }
    }
  }, [locationData])

  useEffect(() => {
    if (locations.data.length !== 0) {
      setLocationData(locations.data)
    }
  },
    [locations.data])

  const getSearchData = (e, showSelected = false) => {
    setToggleSearchList(true)
    setSearchValue(e.target.value)
    setTypedSearch(e.target.value)
    if (e.target.value === '') { return }
    clearTimeout(searchTimer)
    cursorPointer = 0
    setCursor(cursorPointer)
    searchTimer = setTimeout(() => {
      const data = {
        query: {
          bool: {
            should: [
              {
                multi_match: {
                  query: e.target.value,
                  fields: ['make', 'model', 'year', 'engine'],
                  type: 'most_fields',
                }
              },
              {
                query_string: {
                  query: '*' + e.target.value + '*',
                  fields: [
                    'partnumber'
                  ]
                }
              }
            ]
          }
        },
        size: 20
      }
      GetSearchList(data).then((response) => {
        if (response?.hits?.hits?.length) {
          setSearchData(response.hits.hits)
          if (showSelected) {
            if (queryParams.get('partNumber')) {
              setSearchValue(queryParams.get('partNumber'))
            } else {
              setSearchValue(queryParams.get('year') + ' ' + queryParams.get('make') + ' ' + queryParams.get('model') + ' ' + queryParams.get('engine'))
            }
            setToggleSearchList(false)
          }
        } else {
          setSearchData([])
        }
      })
    }, 500)
  }

  const setSearchFields = (d, type) => {
    if (type === 'applications') {
      history.push('/application-search?search=' + typedSearch + '&year=' + d._source.year + '&make=' + d._source.make + '&model=' + d._source.model + '&engine=' + d._source.engine.split(' ').shift() + '&groupId=' + d._source.vehicletypegroupid)
      setSearchValue(d._source.year + ' ' + d._source.make + ' ' + d._source.model + ' ' + d._source.engine)
    }
    if (type === 'parts') {
      history.push('/application-search?search=' + typedSearch + '&partNumber=' + d._source.partnumber)
      setSearchValue(d._source.partnumber)
    }
  }

  useEffect(() => {
    if (localStorage.getItem('commonData') !== null) {
      setSiteData(JSON.parse(localStorage.getItem('commonData')).siteData)
    }
  }, [localStorage])

  function useOutsideAlerter(ref, close, pref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && pref.current.contains(event.target)) {
          setToggleMegaMenu(false)
          setToggleSidebar(false)
          setMegaOpen(!megaOpen)
          document.querySelector('body').classList.remove('my-class')
        } else if (ref.current && !ref.current.contains(event.target)) {
          setToggleMegaMenu(false)
          setToggleSidebar(false)
          document.querySelector('body').classList.remove('my-class')
        }
      }

      close && document.addEventListener('mousedown', handleClickOutside)
      return () => {
        close && document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [close])
  }

  const showSidebar = (e, path) => {
    e.preventDefault()
    setToggleSidebar(prevState => {
      const current = !prevState
      if (current) {
        document.body.classList.add('my-class')
      } else {
        document.querySelector('body').classList.remove('my-class')
      }
      return current
    })
    if (path) {
      history.push(`/${path}`)
    }
  }

  const showMobileMenuOne = (e, path) => {
    e.preventDefault()
    if (scrollState.menuOne) {
      setScrollState(prevState => ({ ...prevState, menuOne: false }))
    } else setScrollState(prevState => ({ ...prevState, menuOne: true }))
    setMobileMenuOne(prevState => (!prevState))
    if (path) {
      history.push(`/${path}`)
    }
  }

  const showMegaMenu = e => {
    e.preventDefault()
    if (megaOpen) {
      setToggleMegaMenu(false)
      setMegaOpen(false)
    } else setToggleMegaMenu(true)
  }

  const closeMegaMenu = e => {
    e.preventDefault()
    setToggleMegaMenu(false)
    setMegaOpen(false)
  }

  const manageLogout = e => {
    e.preventDefault()
    localStorage.removeItem('redirectToOrderCSV')
    localStorage.removeItem('profile')
    localStorage.removeItem('token')
    localStorage.removeItem('selectedLocation')
    localStorage.removeItem('redirectTo')
    sessionStorage.clear()
    dispatch(removeLocations())
    // dispatch({ type: LOGIN_SUCCESS, payload: 0 })
    // history.push('/')
    setTimeout(() => {
      window.location = '/'
    }, 200)
  }

  const updateLocation = location => e => {
    e.preventDefault()
    localStorage.removeItem('selectedLocation')
    setSelectedLocation(location)
    dispatch(selectLocation(location))
    localStorage.setItem('selectedLocation', JSON.stringify(location))
    history.push('/')
  }

  const handleKeyPress = (event) => {
    if (event.key === 'ArrowUp') {
      event.preventDefault()
      if (cursorPointer !== 0) {
        cursorPointer--
        setCursor(cursorPointer)
        refs[searchData[cursor]._id].current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
          top: -10
        })
      }
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      if (cursorPointer < searchData.length - 1) {
        cursorPointer++
        setCursor(cursorPointer)
        refs[searchData[cursor]._id].current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
          top: -10
        })
      }
    }
    if (event.key === 'Enter') {
      setSearchFields(searchData[cursor], searchData[cursor]._index)
    }
  }

  const refs = searchData.reduce((acc, value) => {
    acc[value._id] = React.createRef()
    return acc
  }, {})

  useOutsideAlerter(wrapperNav, toggleMegaMenu, productRef)
  useOutsideAlerter(wrapperCart, toggleSidebar, cartRef)

  const handleApplicationSearch = () => {
    if (history.location.pathname === '/application-search') {
      setApplicationSearchCount(prevState => (
        prevState + 1
      ))
    } else {
      history.push('/application-search')
    }
  }

  return (
    <div id='desktop__header'>
      <Suspense fallback={<div></div>}>
        <MiniCart
          toggleSidebar={toggleSidebar}
          closeMiniCart={wrapperCart}
          showSidebar={showSidebar}
        />
      </Suspense>
      <Suspense fallback={<div></div>}>
        <MobileMenu
          mobileMenuOne={mobileMenuOne}
          showMobileMenuOne={showMobileMenuOne}
          name={userData.isAuthenticated
            ? `${userData.user.firstName} ${userData.user.lastName}`
            : null}
        />
      </Suspense>
      <Suspense fallback={<div></div>}>
        <MegaMenu
          toggleMegaMenu={toggleMegaMenu}
          closeMegaMenu={wrapperNav}
          showMegaMenu={showMegaMenu}
          exitMegaMenu={closeMegaMenu}
        />
      </Suspense>
      <div>
        <div className="desktop__header">
          <div className="header__navbar-main d-flex align-items-center">
            <div className="container d-flex justify-content-between align-items-center h-100">
              <div className="d-flex">
                <Link to="/" className="ql-logo-header">
                  <LazyLoadImage effect="blur" src={siteDetails?.logo || siteData?.logo} alt="QLlogo" />
                </Link>
              </div>

              <div className=" d-flex justify-content-center">
                <div className="form-group has-search-right m-0">
                  <a className="form-control-feedback">
                    <FaSearch className="cursor-p" />
                  </a>
                  <div className='search-app border-none'>
                    <input
                      type="text"
                      id="global-search"
                      className="form-control search-app"
                      placeholder={searchPlaceholder}
                      onChange={(e) => getSearchData(e)}
                      value={searchValue}
                      onBlur={() => setTimeout(() => { setToggleSearchList(false) }, 500)}
                      onFocus={() => { setToggleSearchList(true) }}
                      onKeyDown={(e) => handleKeyPress(e)}
                    />
                    {
                      searchData.length && toggleSearchList
                        ? <ul>
                          {
                            searchData.map((d, k) => {
                              return (
                                d._index === 'applications'
                                  ? <li ref={refs[d._id]} className={k === cursor ? 'active' : 'inactive'} onClick={() => { setSearchFields(d, 'applications') }} key={k}>{d._source.year + ' ' + d._source.make + ' ' + d._source.model + ' ' + d._source.engine}</li>
                                  : <li ref={refs[d._id]} className={k === cursor ? 'active' : 'inactive'} onClick={() => { setSearchFields(d, 'parts') }} key={k}>{d._source.partnumber}</li>
                              )
                            })
                          }
                        </ul>
                        : ''
                    }
                  </div>
                </div>
              </div>
              <div className={`mt-2 d-flex align-items-center h-100 header--location position-relative`}>
                <div className='cursor-p'>
                  {(userData.isAuthenticated && locationData)
                    ? (
                      <>
                        <a className="link-cursor location-main-div d-flex align-items-center">
                          <IoLocationOutline className='header-location-logo' />
                          <span
                            className="Login-Register header-loction-div">
                            <span className="header-location">Location</span>
                            <div className="d-flex align-items-center ">
                              <div className='header-location-container'>
                                <span>{selectedLocation?.shippingCityName},&nbsp; {selectedLocation?.shippingStateName}</span>
                              </div>
                                <MdKeyboardArrowDown className='h5 m-0' />
                            </div>
                          </span>
                        </a>
                      </>
                    )
                    : null}
                </div>

                {
                  <div className="header-dropbox">
                    {locationData?.map((item, i) => (
                      <div
                        onClick={updateLocation(item)}
                        key={i}
                        className="hover-eff header-dropbox-account"
                      >
                        {item?.locationName}, {item?.shippingCityName},{' '}
                        {item?.shippingStateName}
                      </div>
                    ))}
                  </div>
                }
              </div>

              <div className=" mt-2 d-flex justify-content-center align-items-center h-100">
                <div className="main__navbar-links d-flex align-items-center h-100">
                  <span className="header__username position-relative d-flex align-items-center h-100 cursor-p">
                    <span className='d-flex align-items-center'>
                      {userData.isAuthenticated
                        ? (
                          <>
                            <span className="rounded-circle bg-secondary p-2 mr-2 text-white" style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '50px',
                              width: '48px'
                            }}

                            >
                              {userData?.user?.firstName?.charAt(0)}
                              {userData?.user?.lastName?.charAt(0)}
                            </span>
                            <a
                              className="link-cursor"
                            >
                              <span className="Login-Register flex-column">
                                <span className="header-location">Hello,</span>
                                <span className='d-flex align-items-center'>
                                  {userData?.user?.firstName?.split(' ')}
                                  <MdKeyboardArrowDown className='h5 m-0' />
                                </span>
                              </span>
                            </a>
                          </>
                        )
                        : (
                          <>
                            <div className='d-flex align-items-center'>
                              <FiUser className='header-user-icon' />
                              <span className="Login-Register">
                                <Link to="/login" className="header__login">
                                  Login
                                </Link>
                                /
                                <Link to="/register" className="header__login">
                                  Register
                                </Link>
                              </span>
                            </div>
                          </>
                        )}
                    </span>
                    {userData.isAuthenticated
                      ? (
                        <>
                          {
                            allowedModules !== undefined && allowedModules.length > 0 && <div className="header-dropbox">
                              {
                                allowedModules.findIndex(x => (x.permission === 'Order Management' || x.permission === 'Staff Order Management') && x.isAccessible === 1) !== -1
                                  ? <Link style={{ color: '#645a5b' }} to="/dashboard">
                                    <div className="hover-eff">
                                      {' '}
                                      Order History{' '}
                                    </div>
                                  </Link>
                                  : ''
                              }
                              {
                                isOpenAccount && allowedModules.findIndex(x => (x.permission === 'Order Management' || x.permission === 'Open Account Management') && x.isAccessible === 1) !== -1
                                  ? <Link style={{ color: '#645a5b' }} to="/invoices">
                                    <div className="hover-eff">
                                      {' '}
                                      Invoices{' '}
                                    </div>
                                  </Link>
                                  : ''
                              }
                              {
                                allowedModules.findIndex(x => x.permission === 'Payment Methods' && x.isAccessible === 1) !== -1
                                  ? <Link style={{ color: '#645a5b' }} to="/manage-payments">
                                    <div className="hover-eff">
                                      {' '}
                                      Saved Payments{' '}
                                    </div>
                                  </Link>
                                  : ''
                              }
                              <Link style={{ color: '#645a5b' }} to="/preferred-products">
                                <div className="hover-eff">
                                  {' '}
                                  {' '}
                                  Preferred Products{' '}
                                </div>
                              </Link>
                              {/* {
                allowedModules.findIndex(x => x.permission === 'Order Management' && x.isAccessible === 1) !== -1
                  ? <Link style={{ color: '#645a5b' }} to="/order-csv">
                  <div className=" hover-eff ">
                      {' '}
                      Bulk Ordering via CSV{' '}
                  </div>
                  </Link>
                  : ''
              } */}
                              {
                                allowedModules.findIndex(x => x.permission === 'Account Details' && x.isAccessible === 1) !== -1
                                  ? <Link to="/account-details" style={{ color: '#645a5b' }}>
                                    <div className="hover-eff">
                                      {' '}
                                      {' '}
                                      Account Details{' '}
                                    </div>
                                  </Link>
                                  : ''
                              }
                              {
                                allowedModules.findIndex(x => x.permission === 'Location Management' && x.isAccessible === 1) !== -1
                                  ? <Link style={{ color: '#645a5b' }} to="/location-management">
                                    <div className="hover-eff">
                                      {' '}
                                      {' '}
                                      Location Management{' '}
                                    </div>
                                  </Link>
                                  : ''
                              }
                              {
                                (allowedModules.findIndex(x => x.permission === 'Order Management' && x.isAccessible === 1) !== -1 && allowedModules.findIndex(x => x.permission === 'Location Management' && x.isAccessible === 1) !== -1 &&
                                  allowedModules.findIndex(x => x.permission === 'Account Details' && x.isAccessible === 1) !== -1 && allowedModules.findIndex(x => x.permission === 'Payment Methods' && x.isAccessible === 1) !== -1 &&
                                  allowedModules.findIndex(x => x.permission === 'Reports' && x.isAccessible === 1) !== -1)
                                  ? <Link style={{ color: '#645a5b' }} to="/staff-management">
                                    <div className="hover-eff">
                                      {' '}
                                      {' '}
                                      Staff Management{' '}
                                    </div>
                                  </Link>
                                  : ''
                              }
                              {
                                allowDropship
                                  ? <Link style={{ color: '#645a5b' }} to="/staff-account-pricing">
                                    <div className="hover-eff">
                                      {' '}
                                      {' '}
                                      Staff Account Pricing
                                    </div>
                                  </Link>
                                  : ''
                              }
                              {
                                allowedModules.findIndex(x => x.permission === 'Reports' && x.isAccessible === 1) !== -1
                                  ? <Link style={{ color: '#645a5b' }} to="/reports">
                                    <div className="hover-eff">
                                      {' '}
                                      {' '}
                                      Reports{' '}
                                    </div>
                                  </Link>
                                  : ''
                              }
                              {
                                allowedModules.findIndex(x => x.permission === 'Order Management' && x.isAccessible === 1) !== -1
                                  ? <Link style={{ color: '#645a5b' }} to="/disputes">
                                    <div className="hover-eff">
                                      {' '}
                                      {' '}
                                   Dispute Management{' '}
                                    </div>
                                  </Link>
                                  : ''
                              }
                              <Link style={{ color: '#645a5b' }} to="/change-password">
                                <div className="hover-eff">
                                  {' '}
                                  {' '}
                                  Change Password{' '}
                                </div>
                              </Link>
                              <hr className="header-hr" />
                              <div
                                className="hover-eff"
                                onClick={manageLogout}
                              >
                                <a style={{ color: '#645a5b' }}> Logout </a>
                              </div>
                            </div>
                          }
                        </>
                      )
                      : null}
                  </span>
                  <span className="Path-2" />
                  <span
                    role="button"
                    ref={cartRef}
                    onClick={showSidebar}
                    className="header-cart-span"
                  >
                    <div className='position-relative'>
                      {
                        totalQuantity > 0 && <div className='total-items-count rounded-circle'>{totalQuantity > 99 ? '99+' : totalQuantity}</div>
                      }
                      <svg width="20" height="20">
                        <circle cx="7" cy="17" r="2" />
                        <circle cx="15" cy="17" r="2" />
                        <path
                          d="M20,4.4V5l-1.8,6.3c-0.1,0.4-0.5,0.7-1,0.7H6.7c-0.4,0-0.8-0.3-1-0.7L3.3,3.9C3.1,3.3,2.6,3,2.1,3H0.4C0.2,3,0,2.8,0,2.6
          V1.4C0,1.2,0.2,1,0.4,1h2.5c1,0,1.8,0.6,2.1,1.6L5.1,3l2.3,6.8c0,0.1,0.2,0.2,0.3,0.2h8.6c0.1,0,0.3-0.1,0.3-0.2l1.3-4.4
          C17.9,5.2,17.7,5,17.5,5H9.4C9.2,5,9,4.8,9,4.6V3.4C9,3.2,9.2,3,9.4,3h9.2C19.4,3,20,3.6,20,4.4z"
                        />
                      </svg>
                    </div>&nbsp;&nbsp;
                    <span className="Login-Register">Cart</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="header_navbar d-flex justify-content-between align-items-center">
            <div className="container d-flex justify-content-between align-items-center">
              <div className=" d-flex align-items-center">
                <span
                  ref={productRef}
                  className="nav-links header-link"
                  role="button"
                  onClick={showMegaMenu}
                >
                  <span
                    className="products product__categories ml-3  d-flex align-items-center"
                    role="button"
                  >
                    <FaBars className="h5 m-0" />
                    <span className="mx-4">Product Categories</span>
                    <FaAngleDown className="h5 m-0" />
                  </span>
                </span>
                <span className="Path-12" />
                <span className="nav-links">
                  <Link to="/about-us" className="header-link">
                    Why QLS
                  </Link>
                </span>
                <span className="resource-link position-relative nav-links mx-4 header-link">
                  <Link to="/industry-links" className="header-link">
                    Resources
                  </Link>
                  <div className="header-dropbox">
                    <a style={{ color: '#645a5b' }} href="/blogs">
                      <div className="hover-eff">
                        Knowledge Database
                      </div>
                    </a>
                    <Link style={{ color: '#645a5b' }} to={`/service-opportunities/${footerData?.slug}`}>
                      <div className="hover-eff">
                        Service Opportunities
                      </div>
                    </Link>
                    <Link style={{ color: '#645a5b' }} to="/industry-links">
                      <div className="hover-eff">
                        Industry Links
                      </div>
                    </Link>
                    <Link style={{ color: '#645a5b' }} to="/product-and-service-support">
                      <div className="hover-eff">
                        Product & Service Support
                      </div>
                    </Link>
                    <Link style={{ color: '#645a5b' }} to="/faqs">
                      <div className="hover-eff">
                        FAQs
                      </div>
                    </Link>
                    <Link style={{ color: '#645a5b' }} to="/about-us">
                      <div className="hover-eff">
                        Why QLS
                      </div>
                    </Link>
                  </div>
                </span>
                <span className="nav-links mr-4">
                  <Link to="/contact-us" className="header-link">
                    Contact Us
                  </Link>
                </span>
                <span className="nav-links">
                  <Link to="/all-products" className="header-link">
                    All Products
                  </Link>
                  {/* <a href="/">All Products</a> */}
                </span>
              </div>

              <div className=" appSearch__container d-flex justify-content-center">
                {userData.isAuthenticated
                  ? <Link to="/order-csv" className='mr-3'>
                    <CustomButton
                      background="#645a5b"
                      fontSize="12px"
                      hoverColor="#464142"
                    >
                      Start a New Order
                    </CustomButton>
                  </Link>
                  : <Link to="/login" className='mr-3'>
                    <CustomButton
                      background="#645a5b"
                      fontSize="12px"
                      hoverColor="#464142"
                      // The handleClick prop will contacin a function which will add redirectToOrder as true to localstorage
                      handleClick={
                        () => {
                          localStorage.setItem('redirectToOrderCSV', true)
                        }
                      }
                    >
                      Start a New Order
                    </CustomButton>
                  </Link>}
                <a onClick={handleApplicationSearch}>
                  <CustomButton
                    background="#ed2c2b"
                    fontSize="12px"
                    hoverColor="#801919"
                  >
                    <FaSearch className="mr-2" />
                    Application Search
                  </CustomButton>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile__menu--div">
          <div className="header_navbar_logo__mobile d-flex justify-content-around align-items-center py-3">
            <div
              className="slider-icon"
              role="button"
              onClick={showMobileMenuOne}
            >
              <FaBars className="h4 m-0 header-bars-logo" />
            </div>

            <div className="logo mt-1">
              <Link to="/">
                <LazyLoadImage effect="blur" src={siteDetails?.logo || siteData?.logo} alt="QLlogo" height="25" />
              </Link>
            </div>

            <div className="cart__login mt-1">
              <Link to="/cart">
                <div className='position-relative'>
                  {
                    totalQuantity > 0 && <div className='total-items-count rounded-circle'>{totalQuantity > 99 ? '99+' : totalQuantity}</div>
                  }
                  <svg width="20" height="20" >
                    <circle cx="7" cy="17" r="2" />
                    <circle cx="15" cy="17" r="2" />
                    <path
                      d="M20,4.4V5l-1.8,6.3c-0.1,0.4-0.5,0.7-1,0.7H6.7c-0.4,0-0.8-0.3-1-0.7L3.3,3.9C3.1,3.3,2.6,3,2.1,3H0.4C0.2,3,0,2.8,0,2.6
          V1.4C0,1.2,0.2,1,0.4,1h2.5c1,0,1.8,0.6,2.1,1.6L5.1,3l2.3,6.8c0,0.1,0.2,0.2,0.3,0.2h8.6c0.1,0,0.3-0.1,0.3-0.2l1.3-4.4
          C17.9,5.2,17.7,5,17.5,5H9.4C9.2,5,9,4.8,9,4.6V3.4C9,3.2,9.2,3,9.4,3h9.2C19.4,3,20,3.6,20,4.4z"
                    />
                  </svg>
                </div>
              </Link>
            </div>
          </div>

          <div className="header_navbar__mobile">
            <div className="mobile-search-wrapper pt-2">
              <div name="search-mobile row d-flex justify-content-center">
                <div className="wrapper-search-mobile form-group has-search-right mx-3">
                  <Link className="form-control-feedback">
                    <FaSearch className="cursor-p" />
                  </Link>
                  <input
                    type="text"
                    id="global-search"
                    className="form-control search-app search-app__mobile"
                    placeholder={searchPlaceholder}
                    onChange={(e) => getSearchData(e)}
                    value={searchValue}
                    onBlur={() => setTimeout(() => { setToggleSearchList(false) }, 500)}
                    onFocus={() => { setToggleSearchList(true) }}
                    onKeyDown={(e) => handleKeyPress(e)}
                  />
                  {
                    searchData.length && toggleSearchList
                      ? <ul>
                        {
                          searchData.map((d, k) => {
                            return (
                              d._index === 'applications'
                                ? <li ref={refs[d._id]} className={k === cursor ? 'active' : 'inactive'} onClick={() => { setSearchFields(d, 'applications') }} key={k}>{d._source.year + ' ' + d._source.make + ' ' + d._source.model + ' ' + d._source.engine}</li>
                                : <li ref={refs[d._id]} className={k === cursor ? 'active' : 'inactive'} onClick={() => { setSearchFields(d, 'parts') }} key={k}>{d._source.partnumber}</li>
                            )
                          })
                        }
                      </ul>
                      : ''
                  }

                </div>
              </div>
            </div>

            <div className="mobile-btn-wrapper mx-3 mt-0 header-search-btn">
              <Link to='/application-search'>
                <button
                  type="submit"
                  className="Application-Search Application-Search__mobile header-search-btn d-flex align-items-center justify-content-center"
                >
                  <AiOutlineSearch className='h6 m-0 mr-2' />
                  <span>Application Search</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

NewHeader.propTypes = {
  isOpenAccount: PropTypes.bool
}

export default connect()(memo(NewHeader))
